import React, { useState, useEffect } from 'react';
import Tree from 'react-d3-tree';

const OrgChart = () => {
  const [orgData, setOrgData] = useState(null);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await fetch('https://nhtran-oa.pages.dev/organization-chart');
        const data = await response.json();

        // Transform the data to the desired format
        const transformedData = transformDataForTree(data.organization);

        setOrgData(transformedData);
      } catch (error) {
        console.error('Failed to fetch organizational data:', error);
      }
    };

    fetchOrgData();
  }, []);

  const treeConfig = {
    nodeSize: { x: 150, y: 500},
    separation: { siblings: 2, nonSiblings: 4 },
    orientation: 'vertical', // Set orientation to vertical
    translate: { x: 500, y: 50 }, // Adjust the translate to move the tree to the right
  };

  // Function to transform the data into the desired format
  const transformDataForTree = (organizationData) => {
    // Extracting departments from organization data
    const departments = organizationData.departments;

    // Transforming data structure
    const transformedData = {
      name: 'Departments',
      children: departments.map((department) => ({
        name: department.name,
        attributes: {
          Manager: department.managerName,
        },
        children: department.employees.map((employee) => ({
          name: employee.name,
          attributes: {
            Department: employee.department,
            Salary: employee.salary,
            Office: employee.office,
            Skills: employee.skills ? employee.skills.join(', ') : '', 
          }
        })),
      })),
    };

    return transformedData;
  };

  
  return (
    <div style={{ width: '100%', height: '700px' }}>
      {orgData ? (
        <Tree
          data={orgData}
          nodeSize={treeConfig.nodeSize}
          separation={treeConfig.separation}
          orientation={treeConfig.orientation}
          translate={treeConfig.translate}
          pathFunc="step" // Set the path function to "elbow"
        />
      ) : (
        <p>Loading organizational data...</p>
      )}
    </div>
  );
};

export default OrgChart;
