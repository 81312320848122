import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Home.css';

const Home = () => {
  const [userData, setUserData] = useState({ name: '', links: [] });

  useEffect(() => {
    // Fetch data from the provided URL
    fetch('https://nhtran-oa.pages.dev/me')
      .then(response => response.json())
      .then(data => setUserData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="block">
      <h1>{userData.name}</h1>
      <ul className="nav flex-row">
        <li className="nav-item-icon">
          <a className="nav-link active" href={userData.homepage} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </li>
        <li className="nav-item-icon">
          <a className="nav-link active" href="https://www.linkedin.com/in/ngoc-tran-4882b1245/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li className="nav-item-icon">
          <a className="nav-link active" href={userData.githubURL} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </li>
      </ul>
      <p>Interesting Fact: {userData.interestingFact}</p>
      <p>Skills: {userData.skills ? userData.skills.join(', ') : ''}</p>
      <div className="center">
        <h2>API endpoints:</h2>
        <ul>
          <li>
            <a href="/organization-chart">/organization-chart</a>
          </li>
          <li>
            <a href="/me">/me</a>
          </li>
          <li>
            <a href="/employee">/employee</a>
          </li>
        </ul>
      </div>
      <div className="center">
        <a href="/orgchart">
            <button>Go to Organization Chart</button>
        </a>
    </div>
    </div>
  );
};

export default Home;
