// OrgChartPage.js
import React from 'react';
import OrgChart from './OrgChart';

const OrgChartPage = () => {
  return (
    <div>
      <h1>Organization Chart</h1>
      <p>This org chart displays the .csv data initially provided using the react-d3-tree library. Click on nodes to collapse/expand.<br></br>Use your mouse to move the chart around by dragging it or zooming in and out. </p>
      <OrgChart />
    </div>
  );
};

export default OrgChartPage;