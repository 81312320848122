// App.js
import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import OrgChartPage from './OrgChartPage';
import Home from './Home';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/orgchart" element={<OrgChartPage />} />
      </Routes>
  );
}

export default App;

